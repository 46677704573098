import revive_payload_client_4sVQNw7RlN from "/app/frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import vuetify_icons_KAumv2pUni from "/app/frontend/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.js";
import unhead_KgADcZ0jPj from "/app/frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/frontend/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/app/frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/app/frontend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import vuetify_no_client_hints_kyYn5AnnAK from "/app/frontend/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.js";
import plugin_AUP22rrBAc from "/app/frontend/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import auth_e0FkfVuy48 from "/app/frontend/src/plugins/auth.ts";
import settings_cLbEkRm6ts from "/app/frontend/src/plugins/settings.ts";
import toast_ammHSktxCk from "/app/frontend/src/plugins/toast.ts";
import vuetify_nuxt_plugin_client_JOtFoHdxcp from "/app/frontend/.nuxt/vuetify-nuxt-plugin.client.mjs";
export default [
  revive_payload_client_4sVQNw7RlN,
  vuetify_icons_KAumv2pUni,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  vuetify_no_client_hints_kyYn5AnnAK,
  plugin_AUP22rrBAc,
  auth_e0FkfVuy48,
  settings_cLbEkRm6ts,
  toast_ammHSktxCk,
  vuetify_nuxt_plugin_client_JOtFoHdxcp
]